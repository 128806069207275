import React from 'react'

function Avviso() {
	return (
		<>
			{new Date() < new Date('2025/01/15') && (
				<div className="avviso">
					<div className="titolo_avviso">
						{/* <b>Pausa estiva</b> */}
						<b>Pausa Natalizia</b>
						{/* <b>Il catalogo edizione 2022 sta per terminare.</b> */}
					</div>
					<div className="testo_avviso">
						Le spedizioni dei premi saranno interrotte dal <b>19/12/2024</b> al <b>06/01/2025</b> inclusi e riprenderanno regolarmente a partire dal <b>07/01/2025</b>.
						<br />
						Tutti i prodotti ordinati dal <b>19/12/2024</b> saranno quindi regolarmente spediti dal <b>07/01/2025</b>.
					</div>
				</div>
			)}
		</>
	)
}

export default Avviso
